import * as React from 'react';
import { graphql} from 'gatsby';
import { Box, Heading, Text, Flex } from '@chakra-ui/react';
import OneColumnText from '../sections/BlogPostSections/OneColumnText';
import SeoComponent from '../components/SeoComponent';

function Contact(props) {
  const { data } = props;
  const sectionData = data?.allSanityConsumerInquiries.nodes[1];

  // console.log('sectionData: ', sectionData);

  /* STYLES */
  const oneColumnTextStyles = {
    fontColor: 'primary',
    fontSizes: {
      'normal': {base:'15px', md:'15px'},
      'h4': {base:'16px', md:'16px'},
      'h3': {base:'18px', md:'18px'},
      'h2': {base:'20px', md:'20px'},
      'h1': {base:'44px'},
    },
    fontWeights: {
      'normal': '400',
      'strong': '600',
    },
    lineHeights: {
      'normal': {base:'25px', md:'25px'},
      'h4': {base:'1'},
      'h3': {base:'1'},
      'h2': {base:'1'},
      'h1': {base:'45px'},
    },
    letterSpacings: {
      'normal': {base:'0px', md:'0px'},
      'h4': {base:'0px', md:'0px'},
      'h3': {base:'0px', md:'0px'},
      'h2': {base:'0px', md:'0px'},
      'h1': {base:'-0.38px', md:'-0.38px'},
    },
    textAlign: {base:'left', md:'left'},
    textAlignCustom: {
      'normal': {base:'left'},
      'h4': {base:'left'},
      'h3': {base:'left'},
      'h2': {base:'left'},
      'h1': {base:'left', msx:'left'},
    },
    mbSection: {base:'0px', md:'0px'},
    mbContainers: {base:'20px', md:'20px'},
    mbBlocksCustom: {
      'normal': {base:'20px'},
      'h4': {base:'10px'},
      'h3': {base:'10px'},
      'h2': {base:'10px'},
      'h1': {base:'46px'},
    },
    hover: {borderColor:'primary'},
    linkFontStyle: 'italic',
    listsLeftMargin: {base:'20px', md:'20px'},
  };

  return (
    <>      
      <SeoComponent
        title={'Contact'} 
      />

      <Flex
        w="100%"
        position="relative"
        justifyContent="center"
        alignItems="center"
        direction="column"
        // bgColor="#F1F0FA"
      >
        {/* PAGE CONTENT */}
        <Box
          w="100%"
          bgColor="#F1F0FA"
        >
          <Flex
            w={{base:'87vw', ms:'87vw', msx:'87vw', md:'87vw', mds:'87vw', lg:'84vw', xl:'1200px'}} 
            mt={{base:'20vw', ms:'18vw', msx:'17vw', md:'14.38vw', xl:'207px'}}
            mx="auto"
            zIndex="1"
            justifyContent="space-between"
            alignItems="flex-start"
            direction={{base:'column', msx:'row'}}
            // border="1px"
          >
            {/* LEFT CONTENT */}
            <Box
              w={{base:'100%', msx:'47.5%'}}
              // border="1px"
            >
              {sectionData._rawPageText && (
                <Box
                  mt="3px"
                >
                  <OneColumnText 
                    _rawOneColumnText={sectionData._rawPageText} 
                    styles={oneColumnTextStyles} 
                  />
                </Box>
              )}
            </Box>

            {/* RIGHT CONTENT */}
            <Box
              w={{base:'100%', msx:'47.5%'}}
              bgColor="#F1F0FA"
              className="contact-form-iframe"
              dangerouslySetInnerHTML={{
                __html: `${sectionData.formIframe}`,
              }}
              // border="1px"
            >
              
            </Box>

          </Flex>
        </Box>

        {/* GRAY SVG */}
        <Box
          w="100%"
          h={{base:'17vw', xl:'246px'}}
          style={{zIndex:0}}
          // display="none"
        >
          <svg 
            viewBox="0 0 500 149" 
            preserveAspectRatio="none" 
            style={{width:'100%', height:'100%', zIndex:0}}
          >
            <path
              fill="#F1F0FA"
              fillOpacity='1'
              // d="M-0.84,142.61 C216.98,113.98 385.15,180.11 501.97,123.86 L500.00,0.00 L0.00,0.00 Z"
              d="M-83.23,143.59 C273.42,-32.05 261.57,257.08 502.54,93.27 L500.00,0.00 L0.00,0.00 Z"
            >
            </path>
          </svg> 
        </Box>
      </Flex>
    </>
  );
}

export const query = graphql`
  query {
    allSanityConsumerInquiries {
      nodes {
        _rawPageText
        formIframe
      }
    }
  }
`;

export default Contact;
